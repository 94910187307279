<template>
  <div class="component-videos">
    <div class="container">
      <div class="header">
        <div class="subject">동영상 강의</div>
        <select class="form-control" v-model="state.tag">
          <option value="">태그</option>
          <option :value="t" v-for="(t, idx) in tags" :key="idx">{{ t }}</option>
        </select>
      </div>
      <div class="videos row">
        <div class="video col-lg-4" v-for="(v, idx) in filteredVideos" :key="idx">
          <div class="wrapper">
            <div class="head">
              <a class="pointer wrapper" @click="$store.commit('setVideo', v.value)">
                <span class="img" :style="{ backgroundImage: `url(https://img.youtube.com/vi/${v.value}/hqdefault.jpg)` }" alt="아이콘"></span>
              </a>
            </div>
            <div class="body">
              <a :href="`https://www.youtube.com/watch?v=${v.value}`" target="_blank" class="title">{{ v.title }}</a>
              <div class="badges">
                <span class="badge badge-primary" :title="`${t}을 이용하여 수업합니다.`" v-for="(t, idx) in v.tools" :key="idx">{{ t }}</span>
                <span class="badge badge-info" v-for="(t, idx) in v.tags" :key="idx">{{ t }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from "@vue/reactivity";
import { useStore } from "vuex";
import defines from "../defines.json";
import { computed } from "@vue/runtime-core";

export default {
  setup() {
    const store = useStore();
    const state = reactive({
      tag: "",
    });

    const tags = [];

    const filteredVideos = computed(() => {
      if (state.tag) {
        const videos = [];

        for (let i in defines.videos) {
          for (let j in defines.videos[i].tags) {
            defines.videos[i].tags[j] === state.tag && videos.push(defines.videos[i]);
          }
        }

        return videos;
      } else {
        return defines.videos;
      }
    });

    for (let i in defines.videos) {
      for (let j in defines.videos[i].tags) {
        const tag = defines.videos[i].tags[j];
        !tags.includes(tag) && tags.push(tag);
      }
    }

    return { store, state, filteredVideos, defines, tags };
  },
};
</script>
<style lang="scss" scoped>
.component-videos {
  background: #f7f7f7;
  padding: 25px 0;

  .container {
    .header {
      position: relative;

      > .subject {
        font-size: 18px;
        margin-bottom: 17.5px;
        font-weight: bold;
        padding: 0 5px;
      }

      > select {
        position: absolute;
        top: 0;
        right: 0;
        width: 165px;
        margin-top: -6px;
      }
    }

    .videos {
      .video {
        margin-bottom: 35px;

        > .wrapper {
          background: #fff;
          border: 1px solid rgba(0, 0, 0, 0.125);

          .head > .wrapper {
            display: block;
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;

            .img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }

          .body {
            padding: 15px;
            padding: 15px;
            height: 89px;
            overflow: hidden;

            .title {
              color: inherit;
              display: inline-block;
              font-weight: 500;
              white-space: nowrap;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .badges {
              padding-bottom: 5px;

              .badge {
                margin-right: 2.5px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      .videos .video {
        margin-bottom: 15px;

        > .wrapper .body {
          height: auto;
        }
      }
    }
  }
}
</style>
